body {
  .ant-menu-submenu.ant-menu-dark .ant-menu-sub {
    background-color: #16171a;
    color: #f5f6f7;
  }

  #customer-card-modal .modal-dialog {
    max-width: 1400px;
    min-width: 300px;
    width: auto;
  }
}
